import CMSChildrenContainer from '@/cmsComponents/ChildrenContainer';
import cmsStyles from '@/cmsComponents/style.module.scss';
import cx from 'classnames';
import dynamic from 'next/dynamic';
import React from 'react';
import { ITestimonial } from 'types/cms';

import TestimonialItem from './item';
import styles from './style.module.scss';

const ImageSlider = dynamic(() => import('@/cmsComponents/ImageSlider'));

interface ITestimonialComponent {
  id: number;
  heading?: string;
  background?: boolean;
  testimonial_collection?: ITestimonial;
  testimonial_collections?: ITestimonial[];
}

export const Testimonial: React.FC<ITestimonialComponent> = ({
  heading,
  background,
  testimonial_collection,
  testimonial_collections,
}) => {
  return (
    <>
      {heading && testimonial_collections && (
        <h5 className={cx(styles.heading, styles.slides_show)}>{heading}</h5>
      )}
      {testimonial_collections && <ImageSlider testimonials={testimonial_collections} />}
      {testimonial_collection && (
        <CMSChildrenContainer>
          <div className={cmsStyles.containerMarginS}>
            {heading && <h5 className={styles.heading}>{heading}</h5>}
            <TestimonialItem background={background} data={testimonial_collection} />
          </div>
        </CMSChildrenContainer>
      )}
    </>
  );
};
