import { MarkdownCMSModule } from '@/cmsComponents/MarkdownCMSModule';
import cx from 'classnames';
import Image from 'next/image';
import React from 'react';
import { ITestimonial } from 'types/cms';

import closeQuote from './img/close_quote.png';
import openQuote from './img/open_quote.png';
import styles from './style.module.scss';

interface IProps {
  background?: boolean;
  data: ITestimonial;
}

export const TestimonialItem: React.FC<IProps> = ({ data, background }) => {
  const { author_name, quote, job_title } = data;
  return (
    <div className={cx(styles.itemContainer, background ? styles.blueBox : styles.none)}>
      <div>
        <div className={styles.beforeQuote}>
          <Image src={openQuote} alt="open quote" />
        </div>

        <MarkdownCMSModule source={quote} className={styles.quote} />

        <div className={styles.afterQuote}>
          <Image src={closeQuote} alt="close quote" />
        </div>
      </div>
      <div className={styles.name}>
        {author_name} {job_title && `, ${job_title}`}
      </div>
    </div>
  );
};

export default TestimonialItem;
